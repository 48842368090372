
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Layout from "./Components/Layout/Layout";
import AllProducts from "./Modules/AllProducts/AllProducts";
import ContactUs from "./Modules/ContactUs/ContactUs";
// import ScreenCategories from "./Modules/Categories/ScreenCategories";
import ProductList from "./Modules/Categories/ProductList";
import Cart from "./Modules/Cart/Cart"
import Home from "./Modules/Home/Home";
import img1 from './skin Images/img1.jpeg';
import img2 from './skin Images/img2.jpeg';
import img3 from './skin Images/img3.jpeg';
import img5 from './skin Images/img5.jpeg';

import img6 from './skin Images/img6.jpeg';
import img7 from './skin Images/img7.jpeg';
import img8 from './skin Images/img8.jpeg';
import Masks from "./Modules/Masks/Masks";
import ScreenCategories from "./Modules/Categories/ScreenCategories";
import ShippingAddressForm from "./Modules/Buy/ShippingAddressForm";
import React, { useEffect, useState } from "react";
import useSelection from "antd/es/table/hooks/useSelection";
import { useSelector } from "react-redux";
import axios from "axios";
import CategoryProducts from "./Components/Layout/CategoryProducts";
import BrandsProducts from "./Components/Layout/BrandsProducts";
import CheckOut from "./Components/Layout/CheckOut";
import Confirmation from "./Components/Layout/Confirmation";
import Terms from "./Modules/Terms/Terms";
import Privacy from "./Modules/Privacy/Privacy";
import Refunds from "./Modules/Refunds/Refunds";
import Skintype from "./Components/Layout/Skintype";
import Skintype2 from "./Components/Layout/SkinType2";

// import Assistance from "./Components/Layout/Assistance";
// import Feedback from "./Components/Layout/Feedback";
// import OrderDetails from "./Components/Layout/OrderDetails";



const companiesData = [
  {
    id: 1,
    name: "company1",
    companyImage: "https://media.licdn.com/dms/image/C4D0BAQGEeX1h2U7TwQ/company-logo_200_200/0/1646895741612/beminimalist_logo?e=2147483647&v=beta&t=dYjVNQsjw1oZWnhTGN7FV7_UFQxLKxOvN4N3l1sO6M4",
    products: [
      {
        id: 1,
        name: "Shampoo",

        images: [img1, img2, img3, img5, img6],
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
        quantity: 1
      },
      {
        id: 2,
        name: "Serum",

        images: [img2, img1, img3, img5, img6],
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged"
        , quantity: 1
      },
      {
        id: 3,
        name: "Sunscream",

        images: [img3, img2, img1, img5, img6],
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged"
        , quantity: 1
      },
      {
        id: 4,
        name: "Scub",

        images: [img5, img2, img1, img5, img6],
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged"
        , quantity: 1
      },
    ],
  },
  {
    id: 2,
    name: "company2",
    companyImage: "https://i.pinimg.com/originals/01/df/ad/01dfadb784cdcd91ebb730d30592b481.png",
    products: [
      {
        id: 4,
        name: "Serum",
        images: [img5, img6, img7, img5, img6],
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged"
        , quantity: 1
      },
      {
        id: 5,
        name: "Conditioner",
        images: [img6, img5, img7, img5, img6],
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged"
        , quantity: 1
      },
    ],
  },
  {
    id: 3,
    name: "company3",
    companyImage: "https://i.pinimg.com/originals/4b/c7/1b/4bc71be1e103635a2f5d1ca0299e42a8.png",
    products: [
      {
        id: 6,
        name: "Serum",
        images: [img7, img6, img5, img5, img6],
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged"
        , quantity: 1
      },
      {
        id: 7,
        name: "Conditioner",
        images: [img1, img2, img3, img5, img6],
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
        quantity: 1
      },

    ],
  },
  {
    id: 4,
    name: "company5",
    companyImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSARklR2N7qq-fEBoj2hXGL4C7-R8o18gKAbtpEcqzRg&s",

    products: [
      {
        id: 8,
        name: "Serum",
        images: [img2, img1, img3, img5, img6],
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged"
        , quantity: 1
      },
      {
        id: 9,
        name: "Conditioner",
        images: [img3, img2, img1, img5, img6],
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged", quantity: 0
      },
    ],
  },
  {
    id: 5,
    name: "company5",
    companyImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLNQ_Kl2q9wBGMJNwHeg5-2sF4KuqnI2Uy5Y6LHTUPvQ&s",
    products: [
      {
        id: 10,
        name: "Serum",
        images: [img5, img6, img7, img5, img6],
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged"
        , quantity: 1
      },
      {
        id: 11,
        name: "Conditioner",
        images: [img6, img5, img7, img5, img6],
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged"
        , quantity: 1
      },
    ],
  },
  {
    id: 6,
    name: "company6",
    companyImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2sa1SdNRP_q309ZU19Q6KeOnRNiDEu9JYKA&s",
    products: [
      {
        id: 12,
        name: "Serum",
        images: [img7, img5, img6, img5, img6],
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged"
        , quantity: 0
      },
      {
        id: 13,
        name: "Shampoo",
        images: [img1, img2, img3],
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged"
        , quantity: 1
      },
    ],
  },
];


function App() {
  const [categories, setCategories] = useState([]);
  const [bestSellers, setBestSellers] = useState([]);

  useEffect(() => {
    async function getBestSellers() {
      try {
        const response = await axios.get(
          `https://noura-be.onrender.com/api/v1/category/getCategory`
        );
        const products = response.data.result;
        setBestSellers(products);
      } catch (error) {
        console.log("Fetching data is not successfull", error);
      }
    }
    getBestSellers();
  }, []);


  const [cart, setCart] = useState([])
  const [selectedProduct, setSelectedProduct] = useState([])
  const handleCart = (selectedProduct) => {
    setCart([...cart, selectedProduct])

  }
  const handleSelectedProduct = (product) => {
    setSelectedProduct([...selectedProduct, product])
  }
  function ScrollToTop() {
    const { pathname } = useLocation();

    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }


  return (
    <BrowserRouter  >
      <ScrollToTop />

      <Routes>






        <Route element={<Layout />}>
          <Route path="/" element={<Home data={companiesData} />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/refunds" element={<Refunds />} />
          <Route path="/Masks" element={<Masks />} />

          <Route path="/AllProducts" element={<AllProducts />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/shippingAddressForm" element={<ShippingAddressForm />} />
          <Route path="/cart" element={<Cart />} />
          <Route path='/checkout' element={<CheckOut />} />
          <Route path="/product/:productId" element={<ScreenCategories />} />
          <Route path="/:categoryName/:categoryId" element={<CategoryProducts />} />
          <Route path="/brand/:brandId" element={<BrandsProducts />} />
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/skintype" element={<Skintype />} />
          <Route path="/skintype2" element={<Skintype2 />} />
          <Route path="/:categoryName/:categoryId" element={<CategoryProducts />} />
          {/* <Route path="/assist" element={<Assistance />} /> */}
          {/* <Route path="/feedback" element={<Feedback />} /> */}
          {/* <Route path="/order-details" element={<OrderDetails />} /> */}
        </Route>
      </Routes>
    </BrowserRouter >
  );
}

export default App;
