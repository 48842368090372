import React from "react";
import { useState } from "react";
import "./faq.css"; // Import the CSS file
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";

const frequentlyAskQuestions = [
  {
    title: "1. What is the basic skincare routine?",
    description:
      " Wash your face with a gentle cleanser to remove dirt, oil, and makeup. Apply a moisturizer to hydrate and protect the skin.Use a broad-spectrum sunscreen with SPF 30 or higher during the day to protect your skin from UV damage.",
  },
  {
    title: "2.How do I determine my skin type?",
    description:
      "Balanced, not too oily or dry.Oily in the T-zone (forehead, nose, chin) and dry or normal elsewhere.",
  },
  {
    title: "3. How often should I exfoliate?",
    description:
      "Exfoliation should be done 1-3 times a week, depending on your skin type and the type of exfoliator used. Over-exfoliating can irritate and damage the skin barrier.",
  },
  {
    title: "4. What are the benefits of using a serum?",
    description:
      "Serums are concentrated formulas designed to deliver powerful ingredients directly to the skin. They can target specific concerns such as fine lines, wrinkles, dark spots, and hydration. Common ingredients include hyaluronic acid, vitamin C, and retinol.",
  },
];

const Faq = () => {
  const [activeId, setActiveId] = useState(null);

  const togglerFunction = index => {
    if (activeId === index) {
      setActiveId(null);
    } else {
      setActiveId(index);
    }
  };

  return (
    <>
      <h2 className="title-name">Frequently Asked Question's</h2>
      <div className="container">
        <div className="accordion" style={{ width: "100%" }}>
          {frequentlyAskQuestions.map((item, i) => (
            <div key={i} className="accordion-item">
              <div
                className={`accordion-header ${activeId === i ? "active" : ""}`}
                onClick={() => togglerFunction(i)}
              >
                <p className="title">{item.title}</p>
                <span className="icon">
                  {activeId === i ? (
                    <i className="fa-solid">
                      <IoMdArrowDropup />
                    </i>
                  ) : (
                    <i className="fa-solid">
                      <IoMdArrowDropdown />
                    </i>
                  )}
                </span>
              </div>
              {activeId === i && (
                <div className="accordion-content">
                  <p>{item.description}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Faq;
