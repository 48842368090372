import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import image from "../../skin Images/Rectangle 4.png";

const Terms = () => {
    return (
        <div className="page-wrapper">
            <section
                className="page-title"
                style={{ backgroundImage: `url(${image})` }}
            >
                <div className="auto-container">
                    <div className="sec-title">
                        <h1>
                            Terms and Conditions <span className="normal-font"></span>
                        </h1>
                        <div className="bread-crumb"></div>
                    </div>
                </div>
            </section>
            <section
                className="default-section other-info"
                style={{ padding: "100px" }}
            >
                <div className="auto-container">
                    <p>Welcome to Nourakoreanskincare. By using our website, you agree to comply with the following terms and conditions.</p>

                    <h3>1. Acceptance of Terms</h3>
                    <p>
                        By accessing and using our website, you accept these terms and conditions in full. If you disagree with any part, you must not use our website.
                    </p>

                    <h3>2. Use of the Website</h3>
                    <p>
                        You must be at least 18 years old to use this website. By using it, you warrant that you are of legal age and agree to provide accurate information.
                    </p>

                    <h3>3. Products and Pricing</h3>
                    <p>
                        All products listed on Nourakoreanskincare are subject to availability and may be withdrawn at any time. Prices may change without prior notice.
                    </p>

                    <h3>4. Payment</h3>
                    <p>
                        We accept payments through secure payment gateways. You agree to pay the total price for the products, including shipping fees, as applicable.
                    </p>

                    <h3>5. Shipping and Delivery</h3>
                    <p>
                        We aim to deliver products within the estimated timeframe, but delays can occur. We are not responsible for delays outside our control.
                    </p>

                    <h3>6. Returns and Refunds</h3>
                    <p>
                        If you are not satisfied with your purchase, you may request a return or refund within 30 days of receipt, provided the product is unused and in its original packaging.
                    </p>

                    <h3>7. Intellectual Property</h3>
                    <p>
                        All content on this website, including images, text, and trademarks, is the property of Nourakoreanskincare. You may not reproduce any content without our written consent.
                    </p>

                    <h3>8. Limitation of Liability</h3>
                    <p>
                        Nourakoreanskincare is not liable for any damages or losses resulting from the use or inability to use the products purchased from our website.
                    </p>

                    <h3>9. Governing Law</h3>
                    <p>
                        These terms and conditions are governed by and construed in accordance with the laws.
                    </p>

                    <h3>10. Amendments</h3>
                    <p>
                        We reserve the right to modify these terms at any time. Continued use of the website following changes implies acceptance of the updated terms.
                    </p>
                </div>
            </section>
            <ToastContainer />
        </div>
    );
};

export default Terms;
