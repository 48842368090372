import { useEffect, useState } from "react";
import img1 from "../../skin Images/img1.jpeg";
import img2 from "../../skin Images/img2.jpeg";
import img3 from "../../skin Images/img3.jpeg";
import img5 from "../../skin Images/img5.jpeg";
import "./screenCategories.css";
import { NavLink, useParams } from "react-router-dom";
import { addItem } from "../../redux/features/cartSlice";
import ShippingAddressForm from "../Buy/ShippingAddressForm";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_ENDPOINTS, BASE_URL } from "../../utils/api";

function ScreenCategories() {
  const [productDetail, setProductDetail] = useState({});
  const [quantity, setQunatity] = useState(1);
  const dispatch = useDispatch();
  //add to cart
  const send = e => {
    dispatch(addItem({ productDetail, quantity }));
  };
  const handleIncrease = () => {
    setQunatity(quantity + 1);
  };
  const handleDecrease = () => {
    setQunatity(quantity - 1);
  };
  const { productId } = useParams();
  useEffect(() => {
    async function getProductDetail() {
      const response = await axios.get(
        `${BASE_URL}${API_ENDPOINTS.getProductsById}/${productId}`
      );
      setProductDetail(response.data.result);
    }
    getProductDetail();
  }, [productId]);
  // const { carts } = useSelector(state => state.allCart);

  // const cartItem =
  //   carts.find(cartItem => cartItem._id === productDetail._id) || {};

  const [images, setImages] = useState({
    img1: img1,
    img2: img2,
    img3: img3,
    img4: img5,
  });
  const [activeImg, setActiveImage] = useState(images.img1);
  const [amount, setAmount] = useState(1);
  return (
    <div
      style={{ marginTop: "30px", marginBottom: "30px" }}
      className="flex flex-col justify-between lg:flex-row lg:gap-16 lg:items-center"
    >
      <div className="flex flex-col gap-6 lg:w-2-4">
        <div style={{ maxWidth: "20%", margin: "0 auto" }}>
          <img
            src={productDetail.imgData}
            alt=""
            style={{ width: "100%", height: "35%" }}
            className="aspect-square object-cover rounded-xl img-large"
          />
        </div>
        <div
          style={{ maxWidth: "50%", margin: "0 auto", gap: "4rem" }}
          className="img-container"
        >
          {/* <img
            src={product.images[1]}
            alt=""
            className="thumbnail-img"
            onClick={() => setActiveImage(product.images[1])}
          />
          <img
            src={product.images[2]}
            alt=""
            className="thumbnail-img"
            onClick={() => setActiveImage(product.images[2])}
          />
          <img
            src={product.images[3]}
            alt=""
            className="thumbnail-img"
            onClick={() => setActiveImage(product.images[3])}
          />
          <img
            src={product.images[4]}
            alt=""
            className="thumbnail-img"
            onClick={() => setActiveImage(product.images[4])}
          /> */}
        </div>
      </div>
      {/* ABOUT */}
      <div
        style={{ maxWidth: "60%", margin: "0 auto" }}
        className="flex flex-col gap-4 lg:w-2-4"
      >
        <div>
          <span
            className="text-violet-600 font-semibold"
            style={{ color: "rgba(111, 135, 81, 1)", fontSize: "2rem" }}
          >
            Special Skincare
          </span>
          <h3 style={{ fontWeight: "bold", fontSize: "2rem" }}>
            {productDetail.productName}
          </h3>
        </div>
        {/* <p className="text-gray-700">{product.description}</p> */}
        <h6 className="text-2xl font-semibold" style={{ fontWeight: "700" }}>
          ₹ {productDetail.price}
        </h6>
        <div className="flex flex-row items-center gap-12">
          <div className="flex flex-row items-center">
            <button
              className="bg-gray-200 py-2 px-5 rounded-lg text-violet-800 text-3xl"
              onClick={handleDecrease}
              style={{ color: "rgba(111, 135, 81, 1)" }}
            >
              -
            </button>
            <span className="py-4 px-6 rounded-lg">{quantity}</span>
            <button
              className="bg-gray-200 py-2 px-4 rounded-lg text-violet-800 text-3xl"
              onClick={handleIncrease}
              style={{ color: "rgba(111, 135, 81, 1)" }}
            >
              +
            </button>
          </div>
          <NavLink to="/cart">
            <button
              className="bg-violet-800 text-white font-semibold py-3 px-16 rounded-xl h-full media-bttn btn-small"
              onClick={() => send(productDetail)}
              style={{ backgroundColor: "rgba(111, 135, 81, 1)" }}
            >
              Add to Cart
            </button>
          </NavLink>
          <NavLink to="/checkout">
            <button
              className="bg-violet-800 text-white font-semibold py-3 px-16 rounded-xl h-full media-bttn btn-small"
              style={{ backgroundColor: "rgba(111, 135, 81, 1)" }}
              onClick={() => send(productDetail)}
            >
              Buy
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default ScreenCategories;
