import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  CardMedia,
  CardActionArea,
} from "@mui/material";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import image from "../../skin Images/img2.jpeg";
function SingleProduct({ product }) {
  return (
    <Card sx={{ width: 325 }} style={{ padding: "10px", marginBottom: "30px" }}>
      <CardActionArea>
        <CardMedia

          component={"img"}
          height="200"
          image={product.imgData}
          alt={product.productName}
          style={{
            borderRadius: "5px",
            opacity: product.status === "Enabled" ? "" : " 0.5",

          }}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {product.status === "Enabled" ? product.productName : <div style={{ color: "red", fontWeight: "bold" }}>Out of Stock</div>}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ fontSize: "1.5rem" }}
          ></Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <NavLink to={`/product/${product._id}`}>
          {product.status === "Enabled" ? <Button
            style={{
              backgroundColor: "rgba(111, 135, 81, 1)",
              color: "white",
              fontSize: "12px",
            }}
            variant="contained"
            size="medium"
          >
            Details
          </Button> : ""}
        </NavLink>
      </CardActions>
    </Card>
  );
}

export default SingleProduct;
