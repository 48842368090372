import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import axios from "axios";
import { API_ENDPOINTS, BASE_URL } from "../../utils/api";
import pagetitle from "../../images/background/page-title-bg.jpg";
import image from "../../skin Images/Rectangle 4.png";

const ContactUs = () => {
  const [error, setError] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const onChangeHandler = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is Required"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
    message: Yup.string().required("Message is Required"),
  });

  const handleClick = async e => {
    e.preventDefault();
    try {
      setError({});
      await validationSchema.validate(formData, { abortEarly: false });

      const response = await axios.post(
        `${BASE_URL}${API_ENDPOINTS.sendEmail}`,
        {
          formData,
        }
      );

      if (response.data.successCode === 1) {
        toast.success("Email is sent to Noura Team");
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      } else {
        toast.error("Email is not sent to Noura Team");
      }
    } catch (err) {
      const newErrors = {};
      if (err.inner && Array.isArray(err.inner)) {
        err.inner.forEach(error => {
          newErrors[error.path] = error.message;
        });
      } else {
        newErrors.general = err.message || "Validation failed";
      }
      setError(newErrors);
    }
  };

  return (
    <div className="page-wrapper">
      <section
        className="page-title"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="auto-container">
          <div className="sec-title">
            <h1>
              Contact <span className="normal-font">Us</span>
            </h1>
            <div className="bread-crumb"></div>
          </div>
        </div>
      </section>
      <section
        className="default-section other-info"
        style={{ padding: "100px" }}
      >
        <div className="auto-container">
          <div className="row clearfix">
            <div className="column info-column col-lg-5 col-md-6 col-sm-12 col-xs-12">
              <article className="inner-box">
                <h3 className="margin-bott-20">Terms & conditions,Privacy Policy,Customer Care</h3>
                <div className="text margin-bott-40">
                  In- Embrace your natural beauty with our nourishing skincare
                  solutions.
                </div>
                <ul className="info-box">
                  <li>
                    <span className="icon flaticon-location" />
                    <strong>Address</strong>Trichy,Tamil nadu
                  </li>
                  <li>
                    <span className="icon flaticon-location" />
                    <strong>Indian & International shipping available</strong>
                  </li>
                  <li>
                    <span className="icon flaticon-technology-5" />
                    <strong>Phone</strong> 9842231872
                  </li>
                  <li>
                    <span className="icon flaticon-interface-1" />
                    <strong>Email</strong> koreanskincarenoura@gmail.com
                  </li>
                  <li>
                    <span className="icon flaticon-interface-1" />
                    <strong>Business Hours</strong> Monday-Saturday 9:30 AM - 5:30 PM
                  </li>
                  {/* <li>
                    <span className="icon flaticon-web" />
                    <strong>Website </strong> www.NouraSkin.com{" "}
                  </li> */}
                </ul>
              </article>
            </div>
            <div className="column form-column col-lg-7 col-md-6 col-sm-12 col-xs-12">
              <h2>Send Message</h2>
              <div className="inner-box contact-form">
                <form onSubmit={handleClick}>
                  <div className="row clearfix">
                    <div className="form-group col-md-6 col-xs-12">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        placeholder="Your Name"
                        onChange={onChangeHandler}
                      />
                      {error.name && <div className="error">{error.name}</div>}
                    </div>
                    <div className="form-group col-md-6 col-xs-12">
                      <input
                        type="text"
                        name="email"
                        value={formData.email}
                        placeholder="Your Email"
                        onChange={onChangeHandler}
                      />
                      {error.email && (
                        <div className="error">{error.email}</div>
                      )}
                    </div>
                    <div className="form-group col-md-12 col-xs-12">
                      <textarea
                        name="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={onChangeHandler}
                      />
                      {error.message && (
                        <div className="error">{error.message}</div>
                      )}
                    </div>
                    <div className="form-group col-md-12 col-xs-12">
                      <div className="text-right">
                        <button
                          type="submit"
                          className="theme-btn"
                          style={{
                            backgroundColor: "rgba(111, 135, 81, 1)",
                            width: "136px",
                            color: "white",
                            height: "46px",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            fontSize: "16px",
                          }}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
};

export default ContactUs;
