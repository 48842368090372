import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import image from "../../skin Images/img3.jpeg";
import { API_ENDPOINTS, BASE_URL } from "../../utils/api";
function Brands() {
  const [brands, setBrands] = useState([]);
  useEffect(() => {
    async function getBrands() {
      try {
        const response = await axios.get(
          `${BASE_URL}${API_ENDPOINTS.getBrands}`
        );

        setBrands(response.data.result);
      } catch (error) {
        console.log("Fetching data is not successfull", error);
      }
    }
    getBrands();
  }, []);

  return (
    <section className="recent-projects">
      <div className="auto-container">
        <div className="sec-title clearfix">
          <div className="pull-left">
            <h2 style={{ color: " rgba(111, 135, 81, 1)" }}>Brands</h2>
          </div>
        </div>
        <div className="row clearfix">
          {/*Default Featured Column*/}
          <article
            className="inner-box wow fadeInLeft"
            data-wow-delay="0ms"
            data-wow-duration="1500ms"
          >
            {brands.map(({ _id, brandName, imgData }) => (
              <div className="column default-featured-column col-md-3 col-sm-6 col-xs-12">
                <React.Fragment key={_id}>
                  <figure className="image-box">
                    <NavLink to={`/brand/${_id}`}>
                      <img
                        src={imgData}
                        style={{ width: "200px", height: "161.203px" }}
                        alt={""}
                      />
                    </NavLink>
                  </figure>
                  <div className="content-box ">
                    <h3>
                      <NavLink to={`/${brandName}/${_id}`}>{brandName}</NavLink>
                    </h3>
                  </div>
                </React.Fragment>
              </div>
            ))}
          </article>
        </div>
      </div>
    </section>
  );
}

export default Brands;
