import React from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import Product from "../Categories/Product";

function ProductList({ data, name, handleSelectedProduct }) {
  const filterCompanyById = name => {
    return data.filter(company => company.name === name);
  };

  const company = filterCompanyById(name)[0];

  return (
    <Container maxWidth="lg" style={{ marginTop: "50px" }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        mb={4}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{
            color: "green",
            fontWeight: "bold",
            fontSize: "2.5rem",
          }}
        >
          Product List
        </Typography>
      </Box>

      <Grid container spacing={5}>
        {company.products.map(product => (
          <Product
            key={product.id}
            product={product}
            handleSelectedProduct={handleSelectedProduct}
          />
        ))}
      </Grid>
    </Container>
  );
}

export default ProductList;
