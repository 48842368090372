import React, { useEffect, useState } from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import SingleProduct from "./SingleProduct";
import { API_ENDPOINTS, BASE_URL } from "../../utils/api";
import "./products.css";
function BrandsProducts() {
  const [products, setProducts] = useState([]);
  const { brandId } = useParams();
  useEffect(() => {
    async function getProducts() {
      try {
        const response = await axios.get(
          `${BASE_URL}${API_ENDPOINTS.getProductsByBrands}/${brandId}`
        );

        setProducts(response.data.resultObject);

      } catch (error) {
        console.log("Fetching data is not successfull", error);
      }
    }
    getProducts();
  }, []);
  return (
    <Container maxWidth="lg" style={{ marginTop: "50px" }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        mb={4}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{
            color: "rgba(111, 135, 81, 1)",
            fontWeight: "bold",
            fontSize: "3rem",
            marginBottom: "20px",
          }}
        >
          Product List
        </Typography>
      </Box>

      <Grid container spacing={5} className="product-grid">
        {products.map(product => (
          <SingleProduct
            key={product.productId}
            product={product}
          // handleSelectedProduct={handleSelectedProduct}
          />
        ))}
      </Grid>
    </Container>
  );
}

export default BrandsProducts;
