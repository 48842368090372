import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import image from "../../skin Images/Rectangle 4.png";

const Privacy = () => {
    return (
        <div className="page-wrapper">
            <section
                className="page-title"
                style={{ backgroundImage: `url(${image})` }}
            >
                <div className="auto-container">
                    <div className="sec-title">
                        <h1>
                            Privacy Policy <span className="normal-font"></span>
                        </h1>
                        <div className="bread-crumb"></div>
                    </div>
                </div>
            </section>
            <section
                className="default-section other-info"
                style={{ padding: "100px" }}
            >
                <div className="auto-container">
                    <p>
                        At Nourakoreanskincare, we are committed to protecting your privacy. This Privacy Policy outlines the information we collect, how we use it, and how we protect it.
                    </p>

                    <h3>1. Information We Collect</h3>
                    <p>
                        We may collect personal information that you provide to us, such as your name, email address, shipping address, and payment details when you make a purchase or sign up for our newsletter.
                    </p>

                    <h3>2. How We Use Your Information</h3>
                    <p>
                        The information we collect is used to process orders, provide customer service, improve our website, and send promotional emails. We do not sell or share your personal information with third parties except as necessary to provide our services.
                    </p>

                    <h3>3. Cookies</h3>
                    <p>
                        Our website uses cookies to enhance user experience. Cookies are small files placed on your computer’s hard drive to help us analyze web traffic and improve our website. You can choose to accept or decline cookies.
                    </p>

                    <h3>4. Data Security</h3>
                    <p>
                        We take appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet is 100% secure.
                    </p>

                    <h3>5. Third-Party Services</h3>
                    <p>
                        We may use third-party services, such as payment gateways or analytics providers, that have their own privacy policies. We recommend reviewing their policies to understand how your data may be used.
                    </p>

                    <h3>6. Your Rights</h3>
                    <p>
                        You have the right to access, correct, or delete your personal information. You can also opt-out of receiving promotional emails by following the unsubscribe link in our communications.
                    </p>

                    <h3>7. Children's Privacy</h3>
                    <p>
                        Our website is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13.
                    </p>

                    <h3>8. Changes to This Policy</h3>
                    <p>
                        We reserve the right to update this Privacy Policy at any time. Any changes will be posted on this page, and your continued use of the website implies acceptance of those changes.
                    </p>

                    <h3>9. Contact Us</h3>
                    <p>
                        If you have any questions or concerns about this Privacy Policy, please contact us at support@nourakoreanskincare.com.
                    </p>
                </div>
            </section>
            <ToastContainer />
        </div>
    );
};

export default Privacy;
