import React from "react";
import "./confirmation.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const Confirmation = () => {
  const { carts, subtotal, customer } = useSelector(state => state.allCart);
  return (
    <div className="confirm-container">
      <header className="confirm-header">
        <h1>Noura Skin Care</h1>
        <h2>Your order is successfully placed</h2>
      </header>
      <main className="confirm-main">
        <p>Dear {`${customer.firstName}+${customer.lastName}`},</p>
        <p>
          Thank you for choosing Noura for your skincare needs! We're thrilled
          to confirm your order with us. Below are the details of your purchase:
        </p>
        <div className="confirm-order-details">
          {carts.map(product => (
            <div key={product._id} className="confirm-order-item">
              <img src={product.imgData} alt="Sunscreen stick" />
              <div>
                <h3>{product.productName}</h3>
                {/* <p>
                  Lorem ipsum dolor sit amet consectetur. Odio eget at et platea
                  tincidunt.
                </p> */}
                <p
                  style={{
                    margin: 0,
                    fontSize: "16px",
                    color: "rgba(0, 0, 0, 1)",
                    fontWeight: "500",
                    fontFamily: "Roboto",
                  }}
                >
                  ₹ {product.price} * {product.quantity}
                </p>
                {/* <div
                  style={{
                    fontSize: "16px",
                    color: "rgba(0, 0, 0, 1)",
                    fontWeight: "500",
                  }}
                >
                  Subtotal ₹ {subtotal}
                </div> */}
              </div>
            </div>
          ))}
        </div>

        <p style={{ fontWeight: "400" }}>
          Please take a moment to review the details above. If you have any
          questions or need to make changes to your order, please contact us
          within the next 24 hours.
        </p>
        <div className="support">
          <h3>Have Any Questions?</h3>
          <p>
            If you have any inquiries or require assistance, don't hesitate to
            reach out to us:
          </p>
          <button className="support-email">
            <Link to="/contactUs" style={{ color: "black" }}>
              {" "}
              Contact Us
            </Link>
          </button>
          {/* <button className="support-phone">Support phone</button> */}
        </div>
      </main>
      <div
        className="contact-footer"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div style={{ marginBottom: "15px" }}>
          <p style={{ display: "flex", justifyContent: "center" }}>
            Thank you for choosing Noura.
          </p>
          <p>
            We can't wait for you to experience the benefits of our products!
          </p>
        </div>
        <div style={{ marginBottom: "15px" }}>
          <p>Warm regards,</p>
        </div>
        <div>
          <p>[Your Name]</p>
          <p>[Your Position/Title]</p>
          <p>[Your Skincare Brand]</p>
          <p>[Customer Support Contact Information]</p>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
