import React from 'react'
import s3 from '../../images/s3.png'
import koon from '../../images/koon.jpg'

const Masks = () => {
  return (
    
<div>
  <div className="page-wrapper">
    <div className="container text-center  about" style={{background: '#FFF', paddingTop: 10}}>
      <h2>Eco Mask Advance</h2>
      <hr />
      <div style={{width: 550, float: 'left', paddingRight: 20}}><img src={s3} alt width="500px" height="300px" />
        <div>
          <div style={{background: '#0C0', margin: '10px 10px 10px 0px', color: '#FFF', fontSize: 20, width: 140, padding: 10, float: 'left', textAlign: 'left'}}><a href="contact.html" style={{color: '#FFF', textAlign: 'center'}}>$12.99</a></div>
          <div style={{textAlign: 'left', fontSize: 24, paddingTop: 14}}>Pack of 2</div>
        </div>
        <div className="clearfix" />
        <div>
          <div style={{background: '#0C0', margin: '10px 10px 10px 0px', color: '#FFF', fontSize: 20, width: 140, padding: 10, float: 'left', textAlign: 'left'}}><a href="contact.html" style={{color: '#FFF', textAlign: 'center'}}>$24.99</a></div>
          <div style={{textAlign: 'left', fontSize: 24, paddingTop: 14}}>Pack of 4</div>
        </div>
      </div>
      <div style={{width: 250, textAlign: 'left', float: 'left'}}>
        <p>
          <strong>Inner Layer:</strong><br />
          Anti-bacterial/Anti-Microbial<br />
          Treatment for fresh finish that<br />
          remains odour free<br /><br />
          <strong>Middle Layer:</strong><br />
          Non-woven (5ply) filters<br />
          with high BFE tested &amp; <br />
          certified by SITRA<br /><br />
          <strong>Outer Layer:</strong><br />
          Water repellent treatment and<br />
          protect from droplets and fluids<br /><br />
          <strong>Flame retardant</strong><br />
          <strong>Eco-friendly &amp; </strong><br />
          <strong>Biodegeradable</strong><br />
          <strong>Reusable upto 25 times</strong> <br />
          <strong>Organic cotton</strong><br /><br />
          <strong style={{fontSize: 30}}>BFE is 96%</strong>
        </p>
        <div style={{background: '#0C0', margin: '10px 10px 10px 0px', color: '#FFF', fontSize: 20, width: 140, padding: 10}}><a href="contact.html" style={{color: '#FFF', textAlign: 'center'}}>Get A Qoute</a></div>
      </div>
      <div style={{width: 300, textAlign: 'left', float: 'right'}}><img src={koon} width={272} height={516} alt /></div>
      <div className="clearfix" />
      <div style={{textAlign: 'left'}}>
        <h2>Details</h2>
        <p>
          <strong>Instructions:</strong> <br />
          •	When you are first fitting mask to your face bend the metal noseband before placing on face and then fit to nose. <br />
          •	Over Ear Style: Wrap Elastic straps around either ear to fit comfortably and securely.<br />
          •	Over Head Style: Take the longest strap and pull over your head down to your neck. Then take shorter strap and fit to the top of your head snuggly. You can adjust the neckband further by tying a knot loop to fit at neck. <br /><br />
          <strong>Filter:</strong> <br />
          •	Adding a filter isn’t necessary but our masks have a pocket located on the bottom of the mask. Ecomask Cotton Facemask has been designed to fit standard PM 2.5 Filters. You may also customize your own filter with a coffee filter or vacuum filter for added protection.
          Care <br />
          •	Although we take all steps in keeping sanitary and healthy production please make sure to launder masks before using and not intended for clinical use. <br /> 
          •	Machine Wash<br />
          •	Tumble Dry Low<br />
          •	Do not Bleach<br />
          •	You may spray with alcohol to disinfect<br /><br />
          <strong>Dimensions: </strong>
          •	One Size Fits Most<br /><br />
          <strong>Material:</strong><br />
          •	Organic Cotton Twill<br />
          •	7oz tight weave for maximum protection but still breathable cotton<br />
          •	Preshrunk &amp; Machine Washable<br />
          •	Certified by SITRA (ASTM G 2101)<br />
          •	100% GOTS certified Cotton<br /><br />
          <strong>Disclaimer</strong>
          Eco mask products are only intended for civilian use and are not a substitution for medical-grade personal protective equipment or any other medical equipment. No representations or claims regarding eco mask products are intended to substitute for professional medical advice.
        </p>
      </div>
    </div>
  </div>
  {/*End pagewrapper*/}
  {/*Scroll to top*/}
  <div className="scroll-to-top scroll-to-target" data-target=".main-header"><span className="fa fa-long-arrow-up" /></div>
</div>


  )
}

export default Masks