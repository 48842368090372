import { Link } from "react-router-dom";
import "./Cart.css";
import img1 from "../../skin Images/img1.jpeg";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCart,
  removeFromCart,
  removeSingleElement,
} from "../../redux/features/cartSlice";
import { addToCart } from "../../redux/features/cartSlice";
function Cart() {
  const dispatch = useDispatch();
  const { carts, subtotal } = useSelector(state => state.allCart);

  const handleRemoveFromCart = id => {
    dispatch(removeFromCart(id));
  };
  const handleDecreaseCart = e => {
    dispatch(removeSingleElement(e));
  };

  const handleClearCart = () => {
    dispatch(clearCart());
  };
  const send = e => {
    dispatch(addToCart(e));
  };

  return (
    <>
      <div className="cart-container">
        <h2>Shopping Cart</h2>
        {carts.length === 0 ? (
          <div className="cart-empty">
            <p>Your cart is currently empty</p>
            <div className="start-shopping">
              <Link to="/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
                <h3 style={{ fontWeight: "bold" }}>Start Shopping</h3>
              </Link>
            </div>
          </div>
        ) : (
          <div>
            <div className="titles">
              <h3
                className="product-title"
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                Product
              </h3>
              <h3
                className="price "
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                Price
              </h3>
              <h3
                className="quantity"
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                Quantity
              </h3>
              <h3
                className="total"
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                Total
              </h3>
            </div>
            <div className="cart-items">
              {carts &&
                carts.map(cartItem => (
                  <div className="cart-item" key={cartItem._id}>
                    <div className="cart-product">
                      <img src={cartItem.imgData} alt={cartItem.productName} />
                      <div>
                        <p
                          style={{
                            fontWeight: "700",
                            fontSize: "20px",
                          }}
                        >
                          {cartItem.productName}
                        </p>
                        <p>{cartItem.desciption}</p>
                        <button
                          onClick={() => handleRemoveFromCart(cartItem._id)}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                    <div
                      className="cart-product-price"
                      style={{
                        fontWeight: "700",
                        fontSize: "20px",
                      }}
                    >
                      {cartItem.price}
                    </div>
                    <div className="cart-product-quantity">
                      <button onClick={() => handleDecreaseCart(cartItem)}>
                        -
                      </button>
                      <div
                        className="count"
                        style={{
                          fontWeight: "700",
                          fontSize: "20px",
                        }}
                      >
                        {cartItem.quantity}
                      </div>
                      <button onClick={() => send(cartItem)}>+</button>
                    </div>
                    <div
                      className="cart-product-total-price"
                      style={{
                        fontWeight: "700",
                        fontSize: "20px",
                      }}
                    >
                      ₹{cartItem.price * cartItem.quantity}
                    </div>
                  </div>
                ))}
            </div>
            <div className="cart-summary">
              <button
                className="clear-btn"
                onClick={() => {
                  handleClearCart();
                }}
              >
                Clear Cart
              </button>
              <div className="cart-checkout">
                <div className="subtotal">
                  <span
                    style={{
                      fontWeight: "700",
                      fontSize: "20px",
                    }}
                  >
                    ₹{subtotal.toFixed(2)}
                  </span>
                  <span className="amount"></span>
                </div>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "13px",
                  }}
                >
                  Taxes and shipping calculated at checkout
                </p>
                <Link to="/checkout">
                  <button style={{ backgroundColor: "rgba(111, 135, 81, 1)" }}>
                    Check out
                  </button>
                </Link>
                <div className="continue-shopping">
                  <Link to="/">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-arrow-left"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                      />
                    </svg>
                    <span
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      Continue Shopping
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Cart;
