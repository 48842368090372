import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import image from "../../skin Images/Rectangle 4.png";

const Refunds = () => {
    return (
        <div className="page-wrapper">
            <section
                className="page-title"
                style={{ backgroundImage: `url(${image})` }}
            >
                <div className="auto-container">
                    <div className="sec-title">
                        <h1>
                            Refund Policy <span className="normal-font"></span>
                        </h1>
                        <div className="bread-crumb"></div>
                    </div>
                </div>
            </section>
            <section
                className="default-section other-info"
                style={{ padding: "100px" }}
            >
                <div className="auto-container">
                    <h2>Refund Policy</h2>
                    <p>
                        At Nourakoreanskincare, we are committed to ensuring your satisfaction with our products. Please review our refund policy for more details on returning items and requesting refunds.
                    </p>

                    <h3>1. Eligibility for Refunds</h3>
                    <p>
                        You may request a refund for unopened and unused products within 30 days of purchase. To be eligible, items must be in the original packaging and in the same condition as when you received them.
                    </p>

                    <h3>2. Non-Refundable Items</h3>
                    <p>
                        Certain products are non-refundable, including:
                        <ul>
                            <li>Opened or used skincare products.</li>
                            <li>Products purchased on sale or with promotional discounts.</li>
                            <li>Gift cards or vouchers.</li>
                        </ul>
                    </p>

                    <h3>3. Refund Process</h3>
                    <p>
                        To initiate a refund, please contact our customer service team at support@nourakoreanskincare.com with your order number and reason for the return. Once approved, you will receive instructions on how to return the item.
                    </p>

                    <h3>4. Shipping Costs</h3>
                    <p>
                        Shipping costs for returning items are the responsibility of the customer, unless the return is due to a defect or an error on our part. Original shipping fees are non-refundable.
                    </p>

                    <h3>5. Refund Approval</h3>
                    <p>
                        Once your return is received and inspected, we will notify you of the approval or rejection of your refund. If approved, a credit will automatically be applied to your original method of payment within a certain amount of days.
                    </p>

                    <h3>6. Late or Missing Refunds</h3>
                    <p>
                        If you haven’t received a refund yet, first check your bank account again. Then contact your credit card company, as it may take some time before your refund is officially posted. If you’ve done this and still have not received your refund, please contact us at support@nourakoreanskincare.com.
                    </p>

                    <h3>7. Exchanges</h3>
                    <p>
                        We only replace items if they are defective or damaged. If you need to exchange a product, contact us at support@nourakoreanskincare.com.
                    </p>
                </div>
            </section>
            <ToastContainer />
        </div>
    );
};

export default Refunds;
