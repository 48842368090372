import React from 'react'
import st from "../../skin Images/skintypenew.jpg"

const Skintype = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={st} alt="skintype" style={{ maxWidth: "100%" }} />
        </div>
    )
}

export default Skintype