import React from "react";
import { Link } from "react-router-dom";
import footerImg from "../../images/background/footer-bg.jpg";
import logo2 from "../../images/logo-2.png";
import logo from "../../skin Images/logo.svg";

const Footer = () => {
  return (
    <div>
      <footer className="main-footer" style={{ backgroundImage: footerImg }}>
        {/*Footer Upper*/}
        <div className="footer-upper">
          <div className="auto-container">
            <div className="row clearfix">
              {/*Two 4th column*/}
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="row clearfix">
                  <div className="col-lg-8 col-sm-6 col-xs-12 column">
                    <div className="footer-widget about-widget">
                      <div className="logo">
                        <Link>
                          {/* <img src={logo} className="img-responsive" alt="Logo" />
                           */}
                          <p style={{ color: 'white', fontSize: "3rem" }}>Noura</p>

                        </Link>
                        <p style={{ fontWeight: "bold", color: "#c9c9c9", marginTop: "10px" }}>Discover the secret to flawless beauty.</p>
                        <div style={{ fontWeight: "bold", color: "#c9c9c9", marginBottom: "50px" }}>India &international shipping available.</div>
                      </div>

                      <ul className="contact-info">

                           <li>
                          <span className="icon fa fa-map-marker" />
                          Trichy, Tamil Nadu, 620001
                        </li>
                        <li>
                          <span className="icon fa fa-phone" /> +91 9842231872
                        </li>
                        <li>
                          <span className="icon fa fa-envelope-o" />{" "}
                          koreanskincarenoura@gmail.com
                        </li>
                        <li>
                          <span>  <Link to={"/terms"}>Terms and conditions</Link></span><span>,</span>  <Link to={"/privacy"}>Privacy policy</Link>
                          <span></span><span>,</span><span> <Link to={"/refunds"}> Refund policy</Link></span>
                          {/* <span className="icon fa fa-envelope-o" />{" "} */}
                        </li>

                        <li>
                          {/* <span className="icon fa fa-envelope-o" />{" "} */}
                          <Link to={"https://www.instagram.com/noura_koreanskincare/?igsh=MTh1M3QzbWMwOWFtNw%3D%3D&utm_source=qr"}> Instagran Link</Link>
                        </li>
                        {/* <li>
                          <span className="icon fa fa-globe" /> www.noura.com
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  {/*Footer Column*/}
                  {/* <div className="col-lg-4 col-sm-6 col-xs-12 column">
                    At NOURA, we focus on eco-friendly, cruelty-free skincare. We partner
                    with conscious brands that prioritize sustainability, gentle ingredients, and
                    animal protection. Here are some of our favorite Korean skincare brands!
                  </div> */}
                  <div style={{ marginTop: "10px" }} >
                    At NOURA, we focus on eco-friendly, cruelty-free skincare. We partner
                    with conscious brands that prioritize sustainability, gentle ingredients, and
                    animal protection. Here are some of our favorite Korean skincare brands!
                  </div>
                </div>
              </div>
              {/*Two 4th column End*/}
              {/*Two 4th column*/}
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="row clearfix">
                  <div className="col-lg-5 col-sm-6 col-xs-12 column">
                    {/* <div className="footer-widget links-widget">
                      <h2>Links</h2>
                      <ul>
                        <li>
                          <Link to="/AllProducts">All Products</Link>
                        </li>
                        <li>
                          <a href="">HEARTLEAF 77% TONER PAD</a>
                        </li>
                        <li>
                          <a href="">
                            HEARTLEAF QUERCETINOL PORE DEEP CLEANSING FOAM 150ML
                          </a>
                        </li>
                        <li>
                          <a href="">NIACINAMIDE 10% + TXA 4% SERUM 30ML</a>
                        </li>
                        <li>
                          <a href="our-mission.html">Our Mission</a>
                        </li>
                        <li>
                          <Link to="/ContactUs">Contact Us</Link>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <div className="col-lg-7 col-sm-6 col-xs-12 column">
                    <div className="footer-widget news-widget">
                      <h2>About Us</h2>
                      <div>
                        At Noura , our primary goal is to foster overall skin
                        health by developing products that nurture, protect, and
                        rejuvenate the skin. We aim to create comprehensive
                        skincare lines that address essential aspects of skin
                        health, such as hydration, protection from environmental
                        stressors, and promoting natural skin renewal.
                      </div>
                      {/* <div className="social-links-two clearfix">
                        <a href="" className="facebook img-circle">
                          <span className="fa fa-facebook-f" />
                        </a>
                        <a href="" className="twitter img-circle">
                          <span className="fa fa-twitter" />
                        </a>
                        <a href="#" className="google-plus img-circle">
                          <span className="fa fa-google-plus" />
                        </a>
                        <a href="" className="linkedin img-circle">
                          <span className="fa fa-pinterest-p" />
                        </a>
                        <a href="" className="linkedin img-circle">
                          <span className="fa fa-linkedin" />
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Footer Bottom*/}
        <div className="footer-bottom">
          <div className="auto-container clearfix">
            {/*Copyright*/}
            <div className="copyright text-left">
              © Copyright 2024. Noura Us. All Rights Reserved
            </div>
          </div>
        </div>
      </footer >
    </div >
  );
};

export default Footer;
