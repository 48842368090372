import { Menu, Space, Tag, Typography, Row, Col } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { API_ENDPOINTS, BASE_URL } from "../../utils/api";
import { Link } from "react-router-dom";

function MegaMenu() {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [bestSellers, setBestSellers] = useState([]);

  const onMenuItemClick = item => {
    setSelectedKeys([item.key]);
  };

  return (
    <div style={{ backgroundColor: "white", padding: "20px 10px" }}>
      <Typography.Title level={3} style={{ textAlign: "center" }}>
        Products List
      </Typography.Title>
      <Row justify="space-around" align="top">
        <Col xs={24} sm={12} lg={6}>
          <Menu
            onClick={onMenuItemClick}
            selectedKeys={selectedKeys}
            mode="inline"
            items={[
              {
                label: "All Products",
                key: "All Products",
                type: "group",
              },

              {
                label: <Link to="/Hair%20care/6710b769b8950016abd8648a">Cleansing Product</Link>,
                key: "All Hair Products",
              },
              {
                label: <Link to="/Exfoliators/6710b6ffb8950016abd86478">All Exfoliators</Link>,
                key: "All Hair Products",
              },
              // {
              //   label: <Link to="/new-arrival">New Arrivals</Link>,
              //   key: "new arrivals",
              // },
              // {
              //   label: <Link to="/best-sellers">Best Sellers</Link>,
              //   key: "best sellers",
              // },
              // { label: "Shop By Category", key: "Shop By Category" },
            ]}
          />
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Menu
            onClick={onMenuItemClick}
            selectedKeys={selectedKeys}
            mode="inline"
            items={[
              { label: "Skin Care", key: "serum", type: "group" },
              {
                label: <Link to="/product/6710bc6ab8950016abd864da">Cleansing Product</Link>,
                key: "Cleansing Product",
              },
              {
                label: <Link to="/Exfoliators/6710b6ffb8950016abd86478">Exfoliators</Link>,
                key: "Exfoliators",
              },
              {
                label: <Link to="/product/6710baaeb8950016abd864ba">Cream</Link>,
                key: "Cream",
              },
              {
                label: <Link to="/product/6710bb19b8950016abd864c2">Retinol</Link>,
                key: "Retinol",
              },
              {
                label: <Link to="/product/6710bd8eb8950016abd864ea">Moisturizers</Link>,
                key: "Moisturizers",
              },


              // {
              //   label: <Link to="/serum">Serum</Link>,
              //   key: "serum",
              // },
              // {
              //   label: <Link to="/masks">Masks</Link>,
              //   key: "masks",
              // },
              // {
              //   label: <Link to="/eye-cream">Eye Cream</Link>,
              //   key: "eye cream",
              // },
              // {
              //   label: <Link to="/sunscreen">Suncreen</Link>,
              //   key: "sunscreen",
              // },
            ]}
          />
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Menu
            onClick={onMenuItemClick}
            selectedKeys={selectedKeys}
            mode="inline"
            items={[
              { label: "Products", key: "products", type: "group" },
              {
                label: (
                  <Link to="/product/6710b8e9b8950016abd864b2">
                    Sun Stick
                  </Link>
                ),
                key: "Sun Stick",
              },
              {
                label: (
                  <Link to="/product/6710bdaeb8950016abd864f2">
                    Salicylic Acid Daily Gentle Cleanser
                  </Link>
                ),
                key: "Salicylic Acid Daily Gentle Cleanser",
              },
              {
                label: (
                  <Link to="/product/6710baaeb8950016abd864ba">
                    Cosrx Centella Blemish Cream
                  </Link>
                ),
                key: "Cosrx Centella Blemish Cream",
              },
              {
                label: (
                  <Link to="/product/671114460b50ab3e7134ac11">
                    Milky Piggy CER-100 Collagen Ceramide Coating Protein Treatment
                  </Link>
                ),
                key: "Milky Piggy CER-100 Collagen Ceramide Coating Protein Treatment",
              },
              // {
              //   label: (
              //     <Link to="/best-anti-aging-products">
              //       Best Anti Aging Product
              //     </Link>
              //   ),
              //   key: "best Anti Aging Product",
              // },
              // {
              //   label: <Link to="/hydrating-skin">Eye Cream</Link>,
              //   key: "hydrating Dry Skin",
              // },
              // {
              //   label: <Link to="/facial-redness">Facial Redness</Link>,
              //   key: "Facial Redness",
              // },
              // {
              //   label: <Link to="/open-pores">Opne Pores</Link>,
              //   key: "open pores",
              // },
              // {
              //   label: <Link to="/pigmentation">Pigmentation</Link>,
              //   key: "pigmentation",
              // },
              // {
              //   label: <Link to="/sensitive">Sensitive</Link>,
              //   key: "sensitive",
              // },
            ]}
          />
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Menu
            onClick={onMenuItemClick}
            selectedKeys={selectedKeys}
            mode="inline"
            items={[
              { label: "Skin type", key: "Skin type", type: "group" },
              // {
              //   label: <Link to="/oily">Oily</Link>,
              //   key: "oily",
              // },
              {
                label: (
                  <>
                    How to check your skin Type?{" "}
                    <Link to="/skintype">
                      Skin Type
                    </Link>
                  </>
                ),
                key: "How to check your skin Type?",
              }, 
              {
                label: (
                  <>
                    To know more about skin {" "}
                    <Link to="/skintype2">
                      Skin Type
                    </Link>
                  </>
                ),
                key: "To know more about Skin Type",
              },
              
              
              // {
              //   label: (
              //     <Link to="/product/671113ab0b50ab3e7134ac06">
              //       Lip Sleeping Mask Berry-8G

              //     </Link>
              //   ),
              //   key: "Lip Sleeping Mask Berry-8G",
              // },
              // {
              //   label: (
              //     <Link to="/product/6711197d0b50ab3e7134ac7c">
              //       Glass Tinted Lip Balm

              //     </Link>
              //   ),
              //   key: "Glass Tinted Lip Balm",
              // },
              // {
              //   label: <Link to="/combination">Exfoliators</Link>,
              //   key: "combination",
              // },
              // {
              //   label: <Link to="/normal">Normal</Link>,
              //   key: "normal",
              // },
              // {
              //   label: <Link to="/treatments">Treatments</Link>,
              //   key: "treatments",
              //   type: "group",
              // },
              // {
              //   label: <Link to="/stayve">Stayve</Link>,
              //   key: "stayve",
              // },
              // {label: <Link to="/ribeskin">Ribeskin</Link>, key: "ribeskin" },
            ]}
          />
        </Col>
      </Row>
    </div >
  );
}

export default MegaMenu;
