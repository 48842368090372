import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customer: {},
  carts: [],
  subtotal: 0,
  discountPercentage: 0,
  maxDiscount: 0,
  discountAmount: 0,
};

const calculateSubtotal = carts => {
  return carts.reduce((total, item) => total + item.price * item.quantity, 0);
};

//card slice
const cartSlice = createSlice({
  name: "cartslice",
  initialState,
  reducers: {
    //add to cart
    addToCart: (state, action) => {
      const Itemindex = state.carts.findIndex(
        item => item._id === action.payload._id
      );
      if (Itemindex >= 0) state.carts[Itemindex].quantity += 1;
      else {
        const temp = {
          quantity: 1,
          ...action.payload,
        };

        state.carts = [...state.carts, temp];
      }

      state.subtotal = calculateSubtotal(state.carts);
      const discountValue = (state.discountPercentage * state.subtotal) / 100;
      if (discountValue > state.maxDiscount) {
        state.subtotal = state.subtotal - state.maxDiscount;
        state.discountAmount = state.maxDiscount;
      } else {
        state.subtotal =
          state.subtotal - (state.discountPercentage * state.subtotal) / 100;
        state.discountAmount = discountValue;
      }
    },
    clearCart: (state, action) => {
      state.carts = [];
      state.subtotal = 0;
      state.discountPercentage = 0;
      state.maxDiscount = 0;
      state.discountAmount = 0;
    },
    removeFromCart: (state, action) => {
      state.carts = state.carts.filter(item => item._id !== action.payload);
      state.subtotal = calculateSubtotal(state.carts);
      state.subtotal =
        state.subtotal - (state.discountPercentage * state.subtotal) / 100;
    },
    removeSingleElement: (state, action) => {
      const itemIndex = state.carts.findIndex(
        item => item._id === action.payload._id
      );
      if (itemIndex >= 0 && state.carts[itemIndex].quantity > 1) {
        state.carts[itemIndex].quantity -= 1;
      } else if (itemIndex >= 0 && state.carts[itemIndex].quantity === 1) {
        state.carts = state.carts.filter(
          item => item._id !== action.payload._id
        );
      }
      state.subtotal = calculateSubtotal(state.carts);
      const discountValue = (state.discountPercentage * state.subtotal) / 100;
      if (discountValue > state.maxDiscount) {
        state.subtotal = state.subtotal - state.maxDiscount;
        state.discountAmount = state.maxDiscount;
      } else {
        state.subtotal =
          state.subtotal - (state.discountPercentage * state.subtotal) / 100;
        state.discountAmount = discountValue;
      }
    },

    addItem: (state, action) => {
      const { productDetail, quantity } = action.payload;
      const itemIndex = state.carts.findIndex(
        item => item._id === productDetail._id
      );

      if (itemIndex !== -1) {
        // If item already exists in cart, add quantity to existing quantity
        state.carts[itemIndex].quantity += quantity;
      } else {
        // If item does not exist in cart, add it with the specified quantity
        state.carts.push({ ...productDetail, quantity });
      }

      // Recalculate subtotal based on the current cart state
      state.subtotal = calculateSubtotal(state.carts);
    },
    addCustomer: (state, action) => {
      state.customer = action.payload;
    },
    removeCoupon: (state, action) => {
      state.discountPercentage = 0;
      state.maxDiscount = 0;
      state.discountAmount = 0;
      state.subtotal = calculateSubtotal(state.carts);
    },
    changeDiscount: (state, action) => {
      state.discountPercentage = action.payload.discountPercentage;
      state.maxDiscount = action.payload.maxDiscountInRs;
      const discountValue = (state.discountPercentage * state.subtotal) / 100;
      if (discountValue > state.maxDiscount) {
        state.subtotal = state.subtotal - state.maxDiscount;
        state.discountAmount = state.maxDiscount;
      } else {
        state.subtotal =
          state.subtotal - (state.discountPercentage * state.subtotal) / 100;
        state.discountAmount = discountValue;
      }
    },
  },
});

export const {
  addToCart,
  clearCart,
  removeFromCart,
  removeSingleElement,
  applyDiscount,
  addItem,
  changeDiscount,
  removeCoupon,
  addCustomer,
} = cartSlice.actions;
export default cartSlice.reducer;
