import "./ShippingAddressForm.css";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import NotesIcon from "@mui/icons-material/Notes";
function ShippingAddressForm() {
  return (
    <div className="form-container">
      <div className="form-body">
        <div className="form-header">
          <div className="form-text">Shipping Address</div>
          <div className="form-underline"></div>
        </div>
        <div className="form-inputs">
          <div className="form-input">
            <PersonIcon className="form-icons" />
            <input type="text" placeholder="Name" name="name" />
          </div>
          <div className="form-input">
            <EmailIcon className="form-icons" />
            <input type="email" placeholder="Email" name="email" />
          </div>
          <div className="form-input">
            <PhoneAndroidIcon className="form-icons" />
            <input type="text" placeholder="Phone Number" name="phoneNumber" />
          </div>
          <div className="form-input">
            <LocalShippingIcon className="form-icons" />
            <input
              type="text"
              placeholder="Delivery Address"
              name="deliveryAddress"
            />
          </div>
          <div className="form-input notes">
            <NotesIcon className="form-icons" />
            <input name="notes" placeholder="Notes" />
          </div>
        </div>
        <div className="form-button">
          <button>Buy</button>
        </div>
      </div>
    </div>
  );
}

export default ShippingAddressForm;
