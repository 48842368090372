import React from 'react'
import skin2 from "../../images/Cream Minimal Elegant New Arrival Skin Care Banner.png"

const Skintype = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={skin2} alt="skintype" style={{ maxWidth: "90%" }} />
        </div>
    )
}

export default Skintype