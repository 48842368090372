import React from 'react'
import pagetitle from '../../images/background/page-title-bg.jpg'
import parallaximg1 from '../../images/parallax/image-1.jpg'
import a1 from '../../images/products/a1.png'
import a88 from '../../images/a88.jpg'

const AllProducts = () => {
  return (
    
<div className="page-wrapper">

  <section className="page-title" style={{backgroundImage: `url(${pagetitle})`}}>
    <div className="auto-container">
      <div className="sec-title">
        <h1>Eco-Friendly Products</h1>
        <div className="bread-crumb"><a href="index.html">Home</a> / <a href="all-products.html" className="current">Eco-Friendly Products</a></div>
      </div>
    </div>
  </section>
  {/*Events Section*/}
  <section className="events-section latest-events">
    <div className="auto-container">
      <div className="row clearfix">                
        {/*Featured Column*/}
        <div className="column default-featured-column style-two col-lg-5 col-md-6 col-sm-6 col-xs-12">
          <article className="inner-box">
            <figure className="image-box">
              <a href="plates.html"><img src={a1} alt /></a>
            </figure>
            <div className="content-box">
              <h3><a href="plates.html"> Plates </a></h3>
              <a href="plates.html" className="theme-btn btn-style-three">View All</a>
            </div>
          </article>
        </div>
        {/*Featured Column*/}
        <div className="column default-featured-column style-two col-lg-5 col-md-6 col-sm-6 col-xs-12">
          <article className="inner-box">
            <figure className="image-box">
              <a href="bowl.html"><img src={a88} alt /></a>
            </figure>
            <div className="content-box">
              <h3><a href="bowl.html"> Bowls</a></h3>
              <a href="bowl.html" className="theme-btn btn-style-three">View All</a>
            </div>
          </article>
        </div>                
      </div>
    </div>
  </section>
  {/*Parallax Section*/}    <section className="parallax-section" style={{backgroundImage: `url(${parallaximg1})`}}>
    <div className="auto-container">
      <div className="text-center">
        <h2>The Best time to <span className="theme_color">plant tree</span> is now</h2>
      </div>
    </div>
  </section>
  {/*Intro Section*/}
  <section className="subscribe-intro">
    <div className="auto-container">
      <div className="row clearfix">
        {/*Column*/}
        <div className="column col-md-9 col-sm-12 col-xs-12">
          <h2>Subcribe for Newsletter</h2>
          For Latest Product Updates and exclusive subscriber offers 
        </div>
        {/*Column*/}
        <div className="column col-md-3 col-sm-12 col-xs-12">
          <div className="text-right padd-top-20">
            <a href="contact.html" className="theme-btn btn-style-one">Subscribe Now</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Main Footer*/}
</div>



  )
}

export default AllProducts