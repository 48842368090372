import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { FaWhatsapp } from "react-icons/fa";
import "./whatsapp.css";
const Layout = () => {
  const handleWhatsAppClick = () => {
    window.open("https://wa.me/9842231872", "_blank");
  };
  return (
    <div>
      <Header />

      {/* <NavBar /> */}
      <div>
        <Outlet />
      </div>
      <div className="whatsapp-icon-container" onClick={handleWhatsAppClick}>
        <FaWhatsapp className="whatsapp-icon" />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
